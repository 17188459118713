



































































































































































































  
import {Component, Vue} from 'vue-property-decorator';
  
@Component({ 
})
export default class HardBlock extends Vue {
  loading: boolean = false;
  onStep: number = 1;
  feedbackTypeId: string | null = null;
  productId: string = '8aa3b033-8134-4532-b5ac-e4bf98b561c8';
  proClicked: boolean = false;
  personalClicked: boolean = false;
  noClicked: boolean = false;
  
  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  get trialEnded()  {
    return this.$store.state.trial_ended
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }
  
  get me() {
    return this.$store.state.me;
  }
  get meId() {
    return this.$store.state.me.id;
  }
  
  goToCheckout() {
    this.proClicked = true;
    // this.$store.commit('set_coupon_code', 'EARLYBIRD20');
    setTimeout(() => this.routeToCheckout(), 1000);
  }

  goToPersonalAccount() {
    this.personalClicked = true;
    this.$gtag.event('pmb_user_hard_block_choice_personal');
    // this.$store.commit('set_coupon_code', 'EARLYBIRD40');
    setTimeout(() => 
      this.routeToCheckout(),
    //@ts-ignore
    this.$parent.close(),
    1000);
    
  }
  
  routeToCheckout() {
    this.$gtag.event('pmb_user_hard_block_choice_pro');
    this.$router.push({name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }
  
  nextStep() {
    this.onStep++;
  }


  mounted() {
    this.$gtag.event('pmb_user_hard_block_mounted');
  }
}
