























































































  
import {Component, Vue} from 'vue-property-decorator';
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';

@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {},
})

export default class FirstFeedback extends Vue {
  loading: boolean = false;
  response: string = "";
  feedbacks: string = "";
  
  feedbackDescription: string = "User: " + this.me.name + "  Email: " + this.me.email + " User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at
  feedbackTypeId: string = ""

  onStep: number = 1;

  

  get workspace() {
    return this.$store.state.workspace
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get meScore() {
    return this.$store.state.me.engagement_score;
  }

  get me() {
    return this.$store.state.me;
  }

  get firstName() {
    return this.$store.state.me.first_name
  }

  nextStep()  { 
    if(this.onStep === 1) {
      this.submitFeedback();
      this.closeModal();
      this.$router.push({ name: 'dashboard-layout' })
    }
    this.onStep++;
  }

  submitFeedback() {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: "Response: " + this.response,
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.$gtag.event('pmb_user_plan_opt_out');
        this.feedbackDescription = "";
        this.$buefy.toast.open({
          message: 'Response submitted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }

  handleResponse(response: number) {
    if(response === 1) {
      this.response = "Yes contact me!";  
    } else if(response === 2) {
      this.response = "No don't contact me";
    } 
    this.nextStep();
  }
  
  mounted() {
    this.$gtag.event('pmb_app_downgrade_modal_mounted');
  }
  
  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }
}
