
































































































  
import {Component, Vue} from 'vue-property-decorator';
import VerifyUser from "@/components/auth/VerifyUser.vue";
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';
  
@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {},
})
export default class FirstOffer extends Vue {
  loading: boolean = false;
  productId: string = '8aa3b033-8134-4532-b5ac-e4bf98b561c8';
  step: number = 1;
  feedbackDescription: string = "User: " + this.me.name + "  Email: " + this.me.email + " User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at
  response: string = "";
  feedbackTypeId: string = ""
  
  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  get me() {
    return this.$store.state.me;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }
  
  claimAccount() {
  
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }

  nextStep() {
    this.$store.commit('set_show_first_offer', null);
    this.submitFeedback()
    this.step = 2;
  }
  
  goToCheckout() {
    // this.$store.commit('set_coupon_code', "EARLYBIRD20");
    this.loading = true;
    setTimeout(() => this.routeToCheckout(), 1000);
  }
  
  routeToCheckout() {
    this.$router.push({name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }

  submitFeedback() {
    
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: "User has accepted Premium trial",
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.$gtag.event('pmb_user_trial_offer_accepted');
        this.feedbackDescription = "";
      });
  }

  handleCloseModal() {
    if(this.onGuestAccount) {
      this.claimAccount()
    }
    this.closeModal();
  }

  closeModal() {
    this.$store.commit('set_show_first_offer', null);

    // @ts-ignore
    this.$parent.close() 
  }

  
  mounted() {
    this.$gtag.event('pmb_app_soft_block_mounted');
  }
  
}
