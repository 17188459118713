

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WorkableItem extends Vue {
  @Prop({ required: false, default: false })
  textEllipsis!: boolean;
}
