















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ObjectiveSuggestions extends Vue {
  get dateList() {
    let work = [
      'Tomorrow',
      'Next week',
      'Next year',
    ];
    return work;
  }

  returnSelected(date: string) {
    this.$emit('selected', date);
  }
}
